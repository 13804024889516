import {useEffect, useState} from 'react';
import { ICountry, IState, ICity } from '../lib/GeoLocationInterface';
import COUNTRIES_JSON from '../lib/allCountries.lite.json'

const KEYS = [
	"name",
	"countryCode",
	"stateCode",
	"latitude",
	"longitude"
]

export function useGeoLocation() {
    const countries = COUNTRIES_JSON;
    const [cities, setCities] = useState<ICity[]>([]);
    const [states, setStates] = useState<IState[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        await Promise.all([fetchCities(), fetchStates()]);
        setLoading(false);
    }

    const fetchStates = async () => {
        const resp = await fetch("https://firebasestorage.googleapis.com/v0/b/uni-chats-engagement-tool.appspot.com/o/admin%2Fstate.json?alt=media&token=66378bf7-bbe6-48be-9272-82958a24efe1");
        const _states = await resp.json();
        setStates(_states);
    }

    const fetchCities = async () => {
        const resp = await fetch("https://firebasestorage.googleapis.com/v0/b/uni-chats-engagement-tool.appspot.com/o/admin%2Fcity.json?alt=media&token=80f02e42-e358-4258-844b-e447590aa825");
        const _cities = await resp.json();
        const convertedCityList = convertArrayToObject(KEYS, _cities);
        setCities(convertedCityList);
    }

    const getStateByCodeAndCountry = async (stateCode: string, countryCode: string): Promise<IState | undefined> => {
        if (!stateCode) return undefined;
        if (!countryCode) return undefined;
        
        const stateList = states;
        return findStateByCodeAndCountryCode(stateList, stateCode, countryCode);
    
    }

    const getCitiesOfCountry = async (countryCode: string): Promise<ICity[] | undefined> => {
        if (!countryCode) return [];
    
        
        const cityList = cities;
        console.log(cityList);
        const _cities = (cityList as ICity[]).filter((value: { countryCode: string }) => {
            return value.countryCode === countryCode;
        });
        return _cities.sort(compare);
    }

    const getAllCountries = ():ICountry[] =>  {
        return countries;
    }


    return {getAllCountries, getStateByCodeAndCountry, getCitiesOfCountry, loading}
}

export const findEntryByCode = (source: any, code: string) => {
	if (code && source != null) {
		const codex = source.findIndex((c: any) => {
			return c.isoCode === code;
		});
		return codex !== -1 ? source[codex] : undefined;
	}
	return undefined;
};

export const findStateByCodeAndCountryCode = (source: any, code: string, countryCode: string) => {
	if (code && countryCode && source != null) {
		const codex = source.findIndex((c: any) => {
			return c.isoCode === code && c.countryCode === countryCode;
		});
		return codex !== -1 ? source[codex] : undefined;
	}
	return undefined;
};

export function defaultKeyToCompare<T extends ICountry | IState | ICity>(entity: T) {
	return entity.name;
}

export const compare = <T extends ICountry | IState | ICity>(
	a: T,
	b: T,
	// eslint-disable-next-line no-unused-vars
	keyToCompare: (entity: T) => string = defaultKeyToCompare,
) => {
	if (keyToCompare(a) < keyToCompare(b)) return -1;
	if (keyToCompare(a) > keyToCompare(b)) return 1;
	return 0;
};

export const convertArrayToObject = (keys: string[], arr: string[][]): ICity[] => {
	const result = arr.map((subArr) => {
		return Object.fromEntries(keys.map((key, index) => [key, subArr[index]]))
	})

	return (result as unknown as ICity[]);
}