import { Button, Group, Table, Text } from '@mantine/core';

import styles from './DataTable.module.scss'
import { ReactNode } from 'react';
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react';



type DataTableProps = {
    header: string[],
    data: (ReactNode | string)[][],
    showPagination?: boolean,
    page?: number,
    pageSize?: number,
    total?: number
    onNextClick?():void
    onPrevClick?():void
}

function DataTable({header =[], data, showPagination=false, page=1, pageSize=5, total=5, onNextClick=()=>{}, onPrevClick=()=>{}}:DataTableProps) {
    const rows = data.map((element, index) => (
        <Table.Tr key={index} bg={"white"}>
            {element.map((col, index2) => {
                return (
                    <Table.Td key={index2}>
                        {col}
                    </Table.Td>
                )
            })}
        </Table.Tr>
      ));

      const prevDisabled = ():boolean => {
        return (page == 1)
      }

      const nextDisabled = ():boolean => {
        if(page == 1)
            return false;
        else if(total/pageSize > page)
            return false;
        else
            return true;
      }

      return (
        <Table withTableBorder borderColor='gray.1' stickyHeader stickyHeaderOffset={0} verticalSpacing="xs" withColumnBorders>
            <Table.Thead className={styles.tableHeader}>
                <Table.Tr>
                    {header.map((h, index) => {
                        return <Table.Th key={index}>{h}</Table.Th>
                    })}
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
            {showPagination && <Table.Caption>
                <Group justify={"end"} gap={"xs"} p={"md"} pt={"xs"}>
                    <Text size={"xs"} c={"gray.5"}>Showing page # {page} </Text>
                    <Button disabled={prevDisabled()} onClick={onPrevClick} size={"sm"} variant={"transparent"} leftSection={<IconArrowLeft size={14} />}>Prev </Button>
                    <Button disabled={nextDisabled()} onClick={onNextClick} size={"sm"} variant={"transparent"} rightSection={<IconArrowRight size={14} />}>Next </Button>
                </Group>
            </Table.Caption>}
        </Table>
      );
}

export default DataTable;