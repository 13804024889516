import { createContext, useState, useEffect, ReactNode } from "react";
import { useAuth } from "../hooks/useAuth";
import { getUserOrgs } from "../services/database";
import { useDisclosure } from "@mantine/hooks";
import _ from "lodash"

import { UserType } from "../models/@type.user";
import { ClientType } from "../models/@type.client";

export type ContextType = {
    MenuBarContent: Object,
    setMenuBarContent(content:Object): void,
    currentUser: UserType | null,
    orgs: ClientType[],
    selected: ClientType | null,
    setSelected(id:string): void,
    initStatus: boolean,
    token: string | null,
    logOut(): void,
    opened: boolean,
    toggle(): void
}

type ContextProps = {
    children: ReactNode
}

export const ConsoleContext = createContext<ContextType | null>(null);;

export function ConsoleContextProvider({children}:ContextProps) { 
    const [opened, { toggle }] = useDisclosure();
    const [ MenuBarContent, setMenuBarContent ] = useState<Object>({});
    const { currentUser, userLoading, token, logOut } = useAuth();
    const [ initStatus, setInitStatus ] = useState<boolean>(false);
    const [ orgs, setOrgs ] = useState<ClientType[]>([])
    const [ selected, setSelectedOrg ] = useState<ClientType | null>(null)
    const [ orgsLoaded, setOrgsLoaded ] = useState<boolean>(false)



    // need to fix this for login.. orgs may not be setted when orgsLoaded
    useEffect(() => {
        if(!userLoading) {
            if(orgsLoaded) {
                setInitStatus(true);
            }
        }
    }, [userLoading, orgsLoaded, currentUser])

    useEffect(() => {
        if(currentUser)
            loadOrgs(currentUser?.uid || "")
        else if(!userLoading)
            if(!currentUser)
                setOrgsLoaded(true);
    }, [currentUser, userLoading])

    async function loadOrgs(id:string) {
    
        const _orgs = await getUserOrgs(id);

        /**
         //don't have a organization
        if(_orgs.length == 0) {
            window.location.href = "https://unifyapply.com";
        }
        */
        setOrgs(_orgs)
        setSelectedOrg(_orgs[0] || null)
        setOrgsLoaded(true)
    }

    function setSelected(id:string) {
        const _selected = _.find(orgs, {id: id}) as ClientType
        setSelectedOrg(_selected)
    }

    return (
        <ConsoleContext.Provider value={{MenuBarContent, setMenuBarContent, currentUser, orgs, selected, setSelected, initStatus, token,logOut, opened, toggle}}>
            {children}
        </ConsoleContext.Provider>
    )

}