// services/firestoreService.ts
import { collection, doc, getDocs, getDoc, addDoc, updateDoc, deleteDoc, query, orderBy, limit, startAfter, DocumentSnapshot, CollectionReference, getCountFromServer, OrderByDirection } from 'firebase/firestore';
import { firestore as db } from "../../services/firebase";


/**
 * Utility to get the Client Snapshot
 * @param {string } clientId - The ID of the client.
 * @return {Promise<firebase.firestore.DocumentSnapshot>} - A promise that resolves to the client snapshot
 */
export const getClient = async (clientId: string) => {
    return await getDoc(doc(db, 'clients', clientId));
};

/**
 * Utility to get a collection count
 * @param {DocumentSnapshot } root - Root Snapshot.
 * @param {string} collectionName - The name of the collection.
 * @return {Promise<number>} - A promise that resolves to the client snapshot
 */
export const getCollectionCount = async (root: DocumentSnapshot, collectionName: string) => {
    let c:CollectionReference =collection(root.ref, collectionName);
    const countSnapshot = await getCountFromServer(c)
    return countSnapshot.data().count || 0;
};

/**
 * Fetches a collection from Firestore with optional pagination.
 * 
 * @param {DocumentSnapshot} root - Root Snapshot.
 * @param {string} collectionName - The name of the collection.
 * @param {number} [limitCount=10] - The number of documents to fetch.
 * @param {DocumentSnapshot} [startAfterDoc] - The document snapshot to start after for pagination.
 * @param {[string, OrderByDirection]} [order] - Optional tuble with the order variable and the direction.
 * @returns {Promise<firebase.firestore.QuerySnapshot>} - A promise that resolves to the collection snapshot.
 */
export const getCollection = async (root: DocumentSnapshot, collectionName: string, limitCount: number = 10, startAfterDoc?: DocumentSnapshot, order?:[string, OrderByDirection]) => {
    
    let c:CollectionReference =collection(root.ref, collectionName);
    let q = query(c, limit(limitCount));
    if(order) {
        q = query(q, orderBy(order[0], order[1]))
    }
    
    if (startAfterDoc) {
      q = query(q, startAfter(startAfterDoc));
    }
    
    return await getDocs(q);
  };
  
/**
 * Fetches a single document from Firestore.
 * 
 * @param {DocumentSnapshot} root - Root Snapshot.
 * @param {string} collectionName - The name of the collection.
 * @param {string} docId - The ID of the document.
 * @returns {Promise<firebase.firestore.DocumentSnapshot>} - A promise that resolves to the document snapshot.
 */
export const getDocument = async (root: DocumentSnapshot, collectionName: string, docId: string) => {
    let c:CollectionReference =collection(root.ref, collectionName);
    return await getDoc(doc(c, docId));
};
  
/**
 * Adds a new document to a Firestore collection.
 * 
 * @param {DocumentSnapshot} root - Root Snapshot.
 * @param {string} collectionName - The name of the collection.
 * @param {any} data - The data to add to the new document.
 * @returns {Promise<firebase.firestore.DocumentReference>} - A promise that resolves to the document reference of the added document.
 */
export const addDocument = async (root: DocumentSnapshot, collectionName: string, data: any) => {
    let c:CollectionReference =collection(root.ref, collectionName);
    delete data.id;
    if(!data.createdAt)
        data.createdAt = Date.now();
    return await addDoc(c, data);
};

/**
 * Updates an existing document in Firestore.
 * 
 * @param {DocumentSnapshot} root - Root Snapshot.
 * @param {string} collectionName - The name of the collection.
 * @param {string} docId - The ID of the document to update.
 * @param {any} data - The new data for the document.
 * @returns {Promise<void>} - A promise that resolves when the document is updated.
 */
export const updateDocument = async (root: DocumentSnapshot, collectionName: string, docId: string, data: any) => {
    delete data.id;
    let c:CollectionReference =collection(root.ref, collectionName);
    return await updateDoc(doc(c, docId), data);
};

/**
 * Deletes a document from Firestore.
 * 
 * @param {DocumentSnapshot} root - Root Snapshot.
 * @param {string} collectionName - The name of the collection.
 * @param {string} docId - The ID of the document to delete.
 * @returns {Promise<void>} - A promise that resolves when the document is deleted.
 */
export const deleteDocument = async (root: DocumentSnapshot, collectionName: string, docId: string) => {
    let c:CollectionReference =collection(root.ref, collectionName);
    return await deleteDoc(doc(c, docId));
};