import { GoogleButton } from '../../components/GoogleButton';
import {
    Title,
    em,
    Text,
    Button,
    Divider,
    Stack,
    Flex,
    TextInput,
    PasswordInput,
    Image
  } from '@mantine/core';

import { useForm } from '@mantine/form';
import { signInWithGoogle, signInWithUnichats } from '../../services/firebase';
import { useContext, useEffect, useState } from 'react';
import { ConsoleContext, ContextType } from '../../context/ConsoleContext';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../../components/Loading';
import { useMediaQuery } from '@mantine/hooks';

import UnichatsLogo from '../../assets/unichats_logo.png';


export function LoginPage() {
    const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
    const { currentUser , initStatus } = useContext(ConsoleContext) as ContextType;
    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
          email: '',
          password: '',
        },
    
        validate: {
          email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
          password: (value) => value? null : 'Password cannot be empty'
        },
    });

    const [waitLoginInWithUnichats, setWaitLoginInWithUnichats] = useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {
        if(!initStatus) return;
        if(currentUser) return navigate("/")
    }, [currentUser, initStatus])

    const onLoginSubmit = async ({email, password}: { email: string; password: string }) => {
        setWaitLoginInWithUnichats(true);
        form.clearErrors();
        const result = await signInWithUnichats(email, password);
        if(!result) {
            form.setErrors({email: "Invalid email or password. Please try again.", password: "Invalid email or password. Please try again."})
        }
        setWaitLoginInWithUnichats(false);
    }

    if(initStatus) {
        return (
            <Flex direction={"row"} justify={"center"} align={"center"} mih={"100vh"} bg={"white"} w={"100%"} >
                <Flex maw={isMobile?"100%":460} w={"100%"} direction={"column"} h={"100vh"} p={"xl"} justify={"space-between"} style={{overflow:"auto"}}>
                    <Image src={UnichatsLogo} w={100} />
                    <Stack>
                        <Title ta={"center"} order={2} fw={600} c={"brand.8"}>
                            Welcome Again!
                        </Title>
                        <Text ta={"center"} c={"dimmed"}>
                            Please log in to manage your chatbot and track leads. Use your email and password or sign in with Google.
                        </Text>
                        <GoogleButton mt={"lg"} onClick={signInWithGoogle} >Google</GoogleButton>
                        <Divider my="xs" label="OR" labelPosition="center" />
                        <form onSubmit={form.onSubmit((values) => onLoginSubmit(values))}>
                            <TextInput
                                size={"md"}
                                withAsterisk
                                label="Email"
                                placeholder="your@email.com"
                                key={form.key('email')}
                                {...form.getInputProps('email')}
                            />
                            <PasswordInput
                                mt={"lg"}
                                size={"md"}
                                withAsterisk
                                label="Password"
                                placeholder="*****"
                                key={form.key('password')}
                                {...form.getInputProps('password')}
                            />
                            
                            <Button size={"lg"} mt={"xl"} w={"100%"} type="submit" disabled={waitLoginInWithUnichats}>{waitLoginInWithUnichats? <Loading/>:"Submit"}</Button>
                        
                        </form>
                    </Stack>
                    <Text ta={"center"} size={"sm"} c={"dimmed"}>
                            Unichats boosting your university's lead generation with A.I.
                    </Text>
                </Flex>
                <Flex flex={1} h={"100vh"} bg={"gray.1"} display={isMobile?"none":"flex"}>
                    
                </Flex>
            </Flex>
        )
    }
    else {
        return (
            <Loading/>
        )
    }
}