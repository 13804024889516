import { ReactNode, useEffect, useState } from "react";
import { useFirestoreCollection } from "../../hooks/useFirestoreCollection";
import { LeadSchema } from "../../models/Lead";
import PageHeader from "../../components/PageHeader";
import { Button, Container, Group, Loader, rem, TextInput, Text, Select, Avatar, Stack, Anchor, Paper } from "@mantine/core";
import styles from './leads.module.scss'
import { IconDownload, IconSearch, IconTable } from "@tabler/icons-react";
import DataTable from "../../components/DataTable";

import pageStyles from '../page.module.scss'



export function LeadIndexPage() {
    const [ pageSize, setPageSize ] = useState<number>(10);
    const { documents, loading, fetchDocuments, documentCount, nextPage, prevPage, page } = useFirestoreCollection('leads', LeadSchema);

    useEffect(() => {
        fetchDocuments(pageSize, ["createdAt", "desc"]);
    },[, pageSize])

    const documentsToTable = () => {
        return documents.map((doc) => {
            return [
                <Group >
                    <Avatar radius="xl" />
                    <Stack gap={0}>
                        <Anchor maw={260} fw={"bold"} size={"sm"}>{doc.firstName} {doc.lastName} {!(doc.firstName && doc.lastName) && "Unknown"}</Anchor>
                        <Text c={"dimmed"} size={"xs"} fw={"bold"}>{doc.sessions?.length} Session{doc.sessions?.length > 1?"s":""}</Text>
                    </Stack>
                </Group> as ReactNode,
                <Stack gap={0}>
                <Text size={"sm"}>{doc.email}</Text>
                <Text size={"sm"}>{doc.phoneNumber}</Text>
                </Stack>,
                (new Date(doc.createdAt as number)).toDateString()
            ]
        })
    }

    return (
        <>
            <PageHeader title={"Leads"}>
                <Button disabled variant={"light"} c={"green"} leftSection={<IconDownload style={{width: rem(16), height: rem(16)}}/>}>Export Leads</Button>
            </PageHeader>
            <Container size={"100%"} className={pageStyles.main}>

                <Paper withBorder radius={"lg"} bg={"gray.1"}>
                    <Group p={"md"} gap={"xs"}>
                        <IconTable color="var(--mantine-color-brand-filled)" /><Text size={"sm"} c={"dimmed"}>This table show the list of the leads captured by Unichats.</Text>
                    </Group>
                <Group className={styles.actionsList} justify={"space-between"} bg="white" p={"md"}> 
                    <TextInput
                        w={300}
                        leftSection={<IconSearch style={{width: rem(16), height: rem(16)}}/>}
                        variant="filled"
                        placeholder="Search by lead email"
                        disabled
                     /> 
                    <Group>
                        <Text size={"sm"}>Showing</Text> 
                        <Select
                            w={75}
                            value={pageSize.toString()}
                            checkIconPosition="right"
                            onChange={(val) => {setPageSize(parseInt(val || "0"))}}
                            variant="filled"
                            data={['10', '15', '25', '50']}
                            />
                        <Text size={"sm"}>of <strong>{!documentCount? " ":documentCount}</strong> results </Text> 
                    </Group>
                            
                </Group>
                {loading?<Group w={"100%"} h={200} justify="center"><Loader type={"dots"}/></Group>:(

                        <DataTable
                            header={["Lead Name", "Contact Info", "Created At"]}
                            data={documentsToTable()}
                            showPagination={true}
                            page={page}
                            pageSize={pageSize}
                            total={documentCount || undefined}
                            onNextClick={nextPage}
                            onPrevClick={prevPage}
                        />

                )}
                </Paper>
            </Container>
        </>
    )
}