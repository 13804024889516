import styles from "./navbar.module.scss"
import Icon from '../assets/unichats_icon.svg'

import { ReactNode, useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { Menu, Group, Stack, Image, Select, NavLink, Text, ScrollArea, Flex, UnstyledButton, Title, Avatar, Tooltip, rem,  ActionIcon } from "@mantine/core"; 
import { IconDashboard, IconMessageBolt, IconCode, IconPaint, IconUrgent, IconPuzzle, IconMessage, IconBrain, IconBook2, IconReportAnalytics, IconPlug, IconMessage2, IconUsers, IconSettings, IconSpeakerphone, IconApps, IconAddressBook, IconForms, IconDeviceAnalytics, IconRobot, IconSchool, IconLocation, IconMessageCircle, IconPower, IconCreditCard, IconUser, IconUserPlus, IconBrowser, IconMessageStar, IconChartBar, IconUsersPlus, IconUsersGroup, IconFlame, IconUserStar, IconApiApp } from "@tabler/icons-react"
import { ConsoleContext, ContextType } from "../context/ConsoleContext";

const MAIN_SECTIONS = [
    {
        value: "conversations",
        label: "Conversations",
        icon: IconMessage2
    },
    {
        value: "leads",
        label: "Leads",
        icon: IconUsers
    },
    {
        value: "analytics",
        label: "Reports",
        icon: IconReportAnalytics
    },
    {
        value: "ambassadors",
        label: "Ambassadors",
        icon: IconUserStar
    },
    {
        value: "integrations",
        label: "Integrations",
        icon: IconPlug
    }
] as const

type SectionsType = typeof MAIN_SECTIONS[number]['value']

const LINKS:{[key:string]:{link: string, label: string, type: "link"|"section", icon: JSX.ElementType, disabled?:boolean}[]} = {
    conversations: [
        {
            link: "/conversations/flows",
            label: "Scripted Conversations",
            icon: IconMessage,
            type: "link"
        },
        {
            link: "/conversations/prompts",
            label: "Dynamic Prompts",
            icon: IconMessageBolt,
            type: "link"
        },
        {
            link: "/conversations/widgets",
            label: "Widgets",
            icon: IconPuzzle,
            type: "link"
        },
        {
            link: "/conversations/promotions",
            label: "Promotional Alerts",
            icon: IconUrgent,
            type: "link",
            disabled: true
        },
        {
            label: "Data",
            type: "section",
            icon: IconDashboard,
            link: ""
        },
        {
            link: "/data/programs",
            label: "Programs",
            icon: IconSchool,
            type: "link"
        },
        {
            link: "/data/knowledge",
            label: "Knowledge",
            icon: IconBook2,
            type: "link"
        },
        {
            label: "Settings",
            type: "section",
            icon: IconDashboard,
            link: ""
        },
        {
            link: "/conversations/forms",
            label: "Forms",
            icon: IconForms,
            type: "link"
        },
        {
            link: "/conversations/themes",
            label: "Chat Theme",
            icon: IconPaint,
            type: "link"
        },
        {
            link: "/conversations/code",
            label: "Installation",
            icon: IconCode,
            type: "link"
        },
        {
            link: "/conversations/integrations",
            label: "Integrations",
            icon: IconApiApp,
            type: "link"
        }
    ],
    leads: [
        {
            link: "/leads/dashboard",
            label: "Stats",
            disabled: true,
            icon: IconDashboard,
            type: "link"
        },
        {
            link: "/leads/new",
            label: "New Leads",
            disabled: true,
            icon: IconUsersPlus,
            type: "link"
        },
        {
            link: "/leads/hot",
            label: "Hot Leads",
            icon: IconFlame,
            disabled: true,
            type: "link"
        },
        {
            link: "/leads/all",
            label: "All Leads",
            icon: IconUsers,
            type: "link"
        },
        {
            label: "Settings",
            type: "section",
            icon: IconDashboard,
            link: ""
        },
        {
            link: "/leads/attributes",
            label: "Fields",
            icon: IconUserPlus,
            type: "link"
        }
    ],
    analytics: [
        {
            link: "/",
            label: "General",
            icon: IconChartBar,
            type: "link"
        },
        {
            label: "Conversations",
            type: "section",
            icon: IconDashboard,
            link: ""
        },
        {
            link: "/analytics/sessions",
            label: "Conversations",
            icon: IconMessageStar,
            type: "link"
        },
        {
            link: "/analytics/conversations",
            label: "Conversations Usage",
            icon: IconMessageStar,
            type: "link"
        },
        {
            link: "/analytics/scripted-conversations",
            label: "Scripted Conversations",
            icon: IconMessageStar,
            type: "link"
        },
        {
            link: "/analytics/ai-conversations",
            label: "AI Conversations",
            disabled: true,
            icon: IconMessageStar,
            type: "link"
        },
        {
            link: "/analytics/questions-map",
            label: "Questions Insights",
            disabled: true,
            icon: IconMessageStar,
            type: "link"
        },
        {
            label: "Leads",
            type: "section",
            icon: IconDashboard,
            link: ""
        },
        {
            link: "/analytics/leads",
            label: "Leads",
            disabled: true,
            icon: IconUserPlus,
            type: "link"
        },
        {
            link: "/analytics/leads-insights",
            label: "Leads Insights",
            disabled: true,
            icon: IconUserPlus,
            type: "link"
        },
        {
            label: "Web Analytics",
            type: "section",
            icon: IconDashboard,
            link: ""
        },
        {
            link: "/analytics/web",
            label: "General",
            disabled: true,
            icon: IconBrowser,
            type: "link"
        },
        {
            link: "/analytics/web-programs",
            label: "Program Page Insights",
            disabled: true,
            icon: IconBrowser,
            type: "link"
        },
        {
            link: "/analytics/web-acquisition",
            label: "Website Acquisition",
            disabled: true,
            icon: IconBrowser,
            type: "link"
        },
    ],
    ambassadors: [
        {
            link: "/ambassadors/",
            label: "Ambassadors",
            disabled: false,
            icon: IconUserStar,
            type: "link"
        },
    ],
    //integrations: []
}

type NavBarComponentProps = {
    collapsed:boolean
}

export function NavBarComponent({collapsed}:NavBarComponentProps) {
    const { orgs, selected, setSelected, logOut } = useContext(ConsoleContext) as ContextType;
    const location = useLocation()

    const locationIndex = MAIN_SECTIONS.findIndex((val) => {
        if(location.pathname.startsWith(`/${val.value}`))
            return true
        else
        return false;
    });
    const initialLocation = MAIN_SECTIONS[ locationIndex >= 0? locationIndex:0].value;

    const [ active, setActive ] = useState<SectionsType>(initialLocation)
    
    const mainLinks = MAIN_SECTIONS.map((link, index) => (
        <Tooltip
            label={link.label}
            position="right"
            withArrow
            transitionProps={{ duration: 0 }}
            key={link.label}
            ml={9}
            >
            <ActionIcon
            
                color={link.value === active ? "brand.5": link.value === initialLocation? "brand.7":"brand.1"}
                variant={link.value === active || link.value === initialLocation ? "filled":"light"}
                size={"lg"}
                onClick={() => setActive(link.value)}
                className={styles.mainLink}
                data-active={link.value === active || link.value === initialLocation || undefined}
            >
            <link.icon style={{ width: rem(22), height: rem(22) }} stroke={1.5} />
          </ActionIcon>
        </Tooltip>
      ));

    function links(section: SectionsType):ReactNode[] | null {
        if(!LINKS[section])
            return null;
        
        return LINKS[section].map((link, index) => {
            if(link.type === "link") {
                return (<NavLink
                    key={index}
                    component={Link}
                    to={link.link}
                    label={link.label}
                    active={location.pathname.startsWith(link.link)}
                    className={styles.navLink}
                    disabled={link.disabled}
                    leftSection={<link.icon size="1rem" stroke={1.5} />}
                />)
            }
            else {
                return <Text key={index} c={"gray.7"} mt={"lg"} mb={"xs"} tt={"uppercase"} size={"xs"} fw={"bold"} pl={"sm"}>{link.label}</Text>
            }
        })
    }

    return (
        <>
            <Flex direction={"row"}>
                <Stack w={55} maw={55} h={"100vh"} bg={"brand.9"}>
                    <Stack h={80} justify="center">
                        <Tooltip
                            label={"Unichats"}
                            position="right"
                            withArrow
                            transitionProps={{ duration: 0 }}
                            key={"Unichats"}
                            >
                        <UnstyledButton component={Link} to={"/"}><Image src={Icon} height={"50px"} /></UnstyledButton>
                        </Tooltip>
                    </Stack>
                    <Stack mt={80} align={"center"}>
                        {mainLinks}
                    </Stack>
                    <Stack mt={"auto"} h={80} justify="center" align="center">
                        <Menu position="right-end" offset={15} trigger="hover" openDelay={100} closeDelay={400}>
                            <Menu.Target><UnstyledButton><Avatar color={"brand.1"} radius="xl" /></UnstyledButton></Menu.Target>
                            <Menu.Dropdown w={200}>
                                
                                <Menu.Item leftSection={<IconSettings size={14} />}>Settings</Menu.Item>
                                <Menu.Item leftSection={<IconUser size={14} />}>Your Account</Menu.Item>
                                <Menu.Item leftSection={<IconCreditCard size={14} />}>Your Plan</Menu.Item>
                                <Menu.Divider />
                                <Menu.Item leftSection={<IconPower size={14} />} onClick={logOut}>Log out</Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    </Stack>
                </Stack>
                <Flex direction={"column"} h={"100vh"} w={!collapsed? 235:0} style={{borderRight: "1px solid var(--mantine-color-gray-2)", display: !collapsed?"": "none"}}>
                    <Stack bg={"gray.0"} gap={0} >
                        <Stack px={24}  h={80} justify="center">
                            <Title c={"brand.8"} order={4}>{active[0].toUpperCase() + active.substring(1)}</Title>
                        </Stack>
                        <Group className={styles.projectSelection} px={26} >
                            <Select
                                checkIconPosition="right"
                                allowDeselect={false}
                                classNames={{input: styles.projectSelect, wrapper: styles.projectSelectWrapper, root: styles.projectSelectInputWrapper}}
                                placeholder="Pick value"
                                data={orgs.map((val) => { return {label: val.name as string, value: val.id as string}})}
                                onChange={(value) => { setSelected(value || "") }}
                                value={selected?.id || null}
                            />
                        </Group>
                    </Stack>
                    <ScrollArea >
                        <Stack px={10} mt={"xl"} gap={0}>
                            {(() => {return links(active)})()}
               
                        </Stack>
                    </ScrollArea>
                </Flex>
            </Flex>
        </>
    )
}