import { ActionIcon, Anchor, Badge, Box, Button, Group,  Text, TextInput, Tooltip  } from "@mantine/core";

import { useEffect, useState } from "react";
import { IconPlus, IconSearch, IconTable, IconTableImport, IconX } from "@tabler/icons-react";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";

import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import { useDebouncedValue } from "@mantine/hooks";
import { SubjectModelType, SubjectSchema } from "../../../models/Subject";
import { ProgramModelType } from "../../../models/Program";

const DEGREE_BADGE_COLOR:{ [key: string]: string } = {
    "bachelor": "green",
    "master": "blue",
    "phd": "violet",
    "diploma": "orange"
}

const STATUS_BADGE_COLOR:{[key: string]: string} = {
    "published": "green",
    "draft": "gray",
    "archived": "orange"
}

const PAGE_SIZE = 10;

type SubjectIndexProps = {
    programs: ProgramModelType[]
}

export function SubjectIndexComponent({programs = []}:SubjectIndexProps) {
    const { documents, loading, fetchDocuments, documentCount } = useFirestoreCollection('subjects', SubjectSchema);
    const [ selectedDocuments, setSelectedDocuments] = useState<SubjectModelType[]>([]);
    const [ paginatedDocuments, setPaginatedDocuments] = useState<SubjectModelType[]>([]);
    const [query, setQuery] = useState('');
    const [debouncedQuery] = useDebouncedValue(query, 200);
    const [selectedRows, setSelectedRows] = useState<SubjectModelType[]>([]);
    const [programCountPerCampus, setProgramCountPerCampus] = useState<{[key:string]: number}>({})

    const [page, setPage] = useState(1);

    const [sortStatus, setSortStatus] = useState<DataTableSortStatus<SubjectModelType>>({
        columnAccessor: 'name',
        direction: 'asc',
      });

    useEffect(() => {
        fetchDocuments(1000);
    }, [])

    useEffect(() => {
        setSelectedDocuments(sortBy(filter(documents,({status}) => {return status != "archived"}), "name") as SubjectModelType[]);
    }, [documents])

    useEffect(() => {
        calculateProgramCount();
    }, [documents, programs])


    useEffect(() => {
        const data = sortBy(selectedDocuments, sortStatus.columnAccessor) as SubjectModelType[];
        setSelectedDocuments(sortStatus.direction === 'desc' ? data.reverse() : data);
    }, [sortStatus]);

    useEffect(() => {
        let data = filter(documents, (doc) => {
            if(doc.status == "archived")
                return false;

            if (
                debouncedQuery !== '' &&
                !`${doc.name}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
              )
            return false;
            
              return true;


        });
        data = sortBy(data, sortStatus.columnAccessor) as SubjectModelType[];
        setSelectedDocuments(sortStatus.direction === 'desc' ? data.reverse() as SubjectModelType[] : data as SubjectModelType[]);
    }, [debouncedQuery])

    useEffect(() => {
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        setPaginatedDocuments(selectedDocuments.slice(from, to))
    }, [page, selectedDocuments])

    function calculateProgramCount() {
        const data:{[key:string]: number} = {}
        documents.map((c) => {
            const pc = filter(programs, (p) => {
                return p.subjects.some((option) => option == c.id);
            })
            data[c.id || "blank"] = pc.length
        })
        setProgramCountPerCampus(data);
    }



    return (
        <>
            <Group bg={"white"} p={"md"} justify="space-between" wrap="nowrap" h={72}>
                <Group wrap="nowrap" w={"100%"}>
                    <TextInput
                        placeholder="Search subject by name"
                        leftSection={<IconSearch size={16} />}
                        w={"100%"}
                        maw={300}
                        value={query}
                        onChange={(e) => setQuery(e.currentTarget.value)}
                        rightSection={
                            <ActionIcon size="sm" variant="transparent" c="dimmed" onClick={() => setQuery('')}>
                            <IconX size={14} />
                            </ActionIcon>
                        }/>
                                    
                </Group>
                <Group justify={"flex-end"}> <Button variant={"light"} color={"brand"} leftSection={<IconPlus/>}> Add a Subject </Button> </Group>
            </Group>
            <DataTable
                key={"Subjects"}
                withTableBorder
                borderRadius="0"
                withColumnBorders
                highlightOnHover
                selectedRecords={selectedRows}
                onSelectedRecordsChange={setSelectedRows}
                borderColor={"var(--mantine-color-gray-2"}
                styles={{
                    root: (theme) => ({
                        border: `1px solid ${theme.colors.gray[1]}`,
                    }),
                    header: (theme) => ({
                        backgroundColor: theme.colors.gray[0],
                        height: "40px",
                        fontWeight: "500",
                        textTransform: "uppercase",
                        fontSize: "small",
                        color: theme.colors.gray[7],
                        borderColor: theme.colors.gray[1]
                    })
                }}
                rowStyle={({}) => (
                    {
                        height: "50px"
                    }
                )}
                records={paginatedDocuments}
                sortStatus={sortStatus}
                onSortStatusChange={setSortStatus}
                totalRecords={selectedDocuments.length}
                recordsPerPage={PAGE_SIZE}
                page={page}
                onPageChange={(p) => setPage(p)}
                idAccessor="name"
                // define columns
                columns={[
                    { accessor: 'name',
                        sortable: true,
                        width: 380,
                        render: ({name}) => (
                            <Box maw={"100%"}><Anchor size="sm" > <Tooltip label={name}><Text fw={"bold"} truncate="end">{name}</Text></Tooltip></Anchor></Box>
                        )
                    },                    
                    { accessor: '# Programs',
                        render: ({id}) => (
                            <Text size={"sm"} c={"dimmed"}>{programCountPerCampus[id || ""] || "0"}</Text>
                        )
                    },
                ]}
            />

        </>
    )

}