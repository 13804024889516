import { ActionIcon, Anchor, Badge, Box, Button, Container, Group, Paper, SegmentedControl, Tabs, Text, TextInput, Tooltip  } from "@mantine/core";
import PageHeader from "../../../components/PageHeader";

import pageStyles from '../../page.module.scss'
import { useEffect, useMemo, useState } from "react";
import { IconSearch, IconTable, IconTableImport, IconX } from "@tabler/icons-react";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { programSchema, ProgramModelType, StudyOptionModelType } from "../../../models/Program";
import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import { useDebouncedValue } from "@mantine/hooks";
import { SubjectIndexComponent } from "../subjects/IndexComponent";
import { CampusIndexComponent } from "../campuses/IndexComponent";

const DEGREE_BADGE_COLOR:{ [key: string]: string } = {
    "bachelor": "green",
    "master": "blue",
    "phd": "violet",
    "diploma": "orange"
}

const STATUS_BADGE_COLOR:{[key: string]: string} = {
    "published": "green",
    "draft": "gray",
    "archived": "orange"
}

const PAGE_SIZE = 10;

export function ProgramIndexPage() {
    const [activeTab, setActiveTab] = useState<string | null>("programs");
    const { documents, loading, fetchDocuments, documentCount } = useFirestoreCollection('programs', programSchema);
    const [ selectedDocuments, setSelectedDocuments] = useState<ProgramModelType[]>([]);
    const [ paginatedDocuments, setPaginatedDocuments] = useState<ProgramModelType[]>([]);
    const [query, setQuery] = useState('');
    const [debouncedQuery] = useDebouncedValue(query, 200);
    const [selectedRows, setSelectedRows] = useState<ProgramModelType[]>([]);

    const [page, setPage] = useState(1);

    const [showStatusFilter, setShowStatusFilter] = useState<string>("all")
    const [sortStatus, setSortStatus] = useState<DataTableSortStatus<ProgramModelType>>({
        columnAccessor: 'name',
        direction: 'asc',
      });

    const notArchivedDocuments = useMemo( () => {
        return filter(documents,({status}) => {return status != "archived"});
    }, [documents])

    useEffect(() => {
        fetchDocuments(1000);
    }, [])

    useEffect(() => {
        setSelectedDocuments(sortBy(filter(documents,({status}) => {return status != "archived"}), "name") as ProgramModelType[]);
    }, [documents])



    useEffect(() => {
        const data = sortBy(selectedDocuments, sortStatus.columnAccessor) as ProgramModelType[];
        setSelectedDocuments(sortStatus.direction === 'desc' ? data.reverse() : data);
    }, [sortStatus]);

    useEffect(() => {
        let data = filter(documents, (doc) => {
            if(doc.status == "archived")
                return false;

            if (
                debouncedQuery !== '' &&
                !`${doc.name}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
              )
            return false;
            
            if(showStatusFilter === "all")
                return true;
            

            return doc.status === showStatusFilter


        });
        data = sortBy(data, sortStatus.columnAccessor) as ProgramModelType[];
        setSelectedDocuments(sortStatus.direction === 'desc' ? data.reverse() as ProgramModelType[] : data as ProgramModelType[]);
    }, [debouncedQuery, showStatusFilter])

    useEffect(() => {
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        setPaginatedDocuments(selectedDocuments.slice(from, to))
    }, [page, selectedDocuments])


    function getCampusList(studyOptions:StudyOptionModelType[]) {
        const _list = studyOptions.map((so) => {
            return so.campus.name
        })
        return [... new Set(_list)];
    }

    return (
        <>
            <PageHeader title={"Programs"}>
                <></>
            </PageHeader>
            <Container size={"100%"} mih={"calc(100vh - 82px)"} className={pageStyles.main}>
                <Paper withBorder radius={"lg"} bg={"gray.1"} style={{overflow: "hidden"}}>
                    <Group gap={"xs"}  p={"md"}>
                        <IconTable color="var(--mantine-color-brand-filled)" /><Text c={"dimmed"} size={"sm"}>This table show the data related to programs, campuses and subjects.</Text>
                    </Group>
                    <Tabs defaultValue={"programs"} value={activeTab} onChange={setActiveTab}>
                        <Tabs.List  bg={"white"} pt={"md"} px={"md"}>
                            <Tabs.Tab value={"programs"}>
                                <Text size={"md"} fw={`${activeTab == "programs"? "900":"normal"}`} c={`${activeTab == "programs"? "brand.7":"gray.7"}`}>Programs</Text>
                            </Tabs.Tab>
                            <Tabs.Tab value={"campuses"}>
                                <Text size={"md"} fw={`${activeTab == "campuses"? "900":"normal"}`} c={`${activeTab == "campuses"? "brand.7":"gray.7"}`}>Campuses</Text>
                            </Tabs.Tab>
                            <Tabs.Tab value={"subjects"}>
                                <Text size={"md"} fw={`${activeTab == "subjects"? "900":"normal"}`} c={`${activeTab == "subjects"? "brand.7":"gray.7"}`}>Subjects</Text>
                            </Tabs.Tab>
                        </Tabs.List>

                        <Tabs.Panel value="programs">
                            <Group bg={"white"} p={"md"} justify="space-between" wrap="nowrap" h={72}>
                                <Group wrap="nowrap" w={"100%"}>
                                    <TextInput
                                    placeholder="Search program by name"
                                    leftSection={<IconSearch size={16} />}
                                    w={"100%"}
                                    maw={300}
                                    value={query}
                                    onChange={(e) => setQuery(e.currentTarget.value)}
                                    rightSection={
                                        <ActionIcon size="sm" variant="transparent" c="dimmed" onClick={() => setQuery('')}>
                                        <IconX size={14} />
                                        </ActionIcon>
                                    }/>
                                    <Group visibleFrom="md" wrap="nowrap" ml={"sm"}>
                                    <Text size={"sm"}  c={"dimmed"}>Show: </Text>
                                    <SegmentedControl
                                        color={"brand.4"}
                                        value={showStatusFilter}
                                        onChange={setShowStatusFilter}
                                        styles={{
                                            root: {
                                                backgroundColor: "transparent"
                                            },
                                            label: {
                                                textTransform: "capitalize"
                                            }
                                        }}
                                        withItemsBorders={false} data={['all', 'published', 'draft']} 
                                        />
                                        </Group>
                                </Group>
                                <Group justify={"flex-end"}> <Button variant={"light"} color={"green"} leftSection={<IconTableImport/>}> Import Programs </Button> </Group>
                            </Group>
                            <DataTable
                            key={"Programs"}
                                withTableBorder
                                borderRadius="0"
                                withColumnBorders
                                highlightOnHover
                                selectedRecords={selectedRows}
                                onSelectedRecordsChange={setSelectedRows}
                                borderColor={"var(--mantine-color-gray-2"}
                                styles={{
                                    root: (theme) => ({
                                        border: `1px solid ${theme.colors.gray[1]}`,
                                    }),
                                    header: (theme) => ({
                                        backgroundColor: theme.colors.gray[0],
                                        height: "40px",
                                        fontWeight: "500",
                                        textTransform: "uppercase",
                                        fontSize: "small",
                                        color: theme.colors.gray[7],
                                        borderColor: theme.colors.gray[1]
                                    })
                                }}
                                rowStyle={({}) => (
                                    {
                                        height: "50px"
                                    }
                                )}
                                records={paginatedDocuments}
                                sortStatus={sortStatus}
                                onSortStatusChange={setSortStatus}
                                totalRecords={selectedDocuments.length}
                                recordsPerPage={PAGE_SIZE}
                                page={page}
                                onPageChange={(p) => setPage(p)}
                                // define columns
                                columns={[
                                    
                                    { accessor: 'name',
                                        sortable: true,
                                        width: 380,
                                        render: ({name}) => (
                                            <Box maw={"100%"}><Anchor size="sm" > <Tooltip label={name}><Text fw={"bold"} truncate="end">{name}</Text></Tooltip></Anchor></Box>
                                        )
                                    },
                                    {
                                        accessor: 'degreeType',
                                        title: 'degree',
                                        sortable: true,
                                        render: ({ degreeType }) => (
                                            <Badge variant={"light"} color={`${DEGREE_BADGE_COLOR[degreeType || ""]}`}>{degreeType}</Badge>
                                        )
                                      
                                    },
                                    { accessor: 'status', 
                                        render: ({ status }) => (
                                            <Badge  variant={"dot"}   color={`${STATUS_BADGE_COLOR[status|| ""]}`}>{status}</Badge>
                                        )
                                    },
                                    /** CANT Implement correctly yet{ accessor: 'subjects', 
                                        render: ({ subjects}) => (
                                            <Group>
                                            {subjects.map((s) => {
                                                return <Badge variant={"dot"} >{s}</Badge>
                                            })}
                                            </Group>
                                        )
                                    },**/
                                    
                                    { accessor: 'studyOptions',
                                        render: ({studyOptions}) => (
                                            <Text size={"sm"} c={"dimmed"}>{(studyOptions || []).length}</Text>
                                        )
                                     },
                                     { accessor: 'campus',
                                        render: ({studyOptions}) => (
                                            <Text size={"sm"} c={"dimmed"}>{getCampusList(studyOptions as StudyOptionModelType[] || []).join(", ")}</Text>
                                        )
                                     },
                                ]}
                                
                                />
                        </Tabs.Panel>
                        <Tabs.Panel value={"subjects"}>
                            <SubjectIndexComponent programs={notArchivedDocuments as ProgramModelType[]}/>
                        </Tabs.Panel>
                        <Tabs.Panel value={"campuses"}>
                            <CampusIndexComponent programs={notArchivedDocuments as ProgramModelType[]}/>
                        </Tabs.Panel>
                    </Tabs>
                </Paper>
            </Container>
        </>
    )

}