import { z } from 'zod';
import { v4 as uuidv4 } from 'uuid';
import { BaseSchema } from './Base';

const locationSchema = z.object({
    city: z.string(),
    isoCode: z.string().optional(),
    countryCode: z.string(),
    country: z.string(),
    stateCode: z.string(),
    state: z.string()
})

export const campusSchema = BaseSchema.extend({
    id: z.string().default(uuidv4).optional(),
    name: z.string().min(3, 'Name must be at least 3 characters long'),
    location: locationSchema,
    status: z.enum(['draft', 'published', 'archived']).default('draft'),
    cover: z.string().optional()
})

export type CampusModelType = z.infer<typeof campusSchema>;