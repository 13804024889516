import { Button, Container } from "@mantine/core";
import { useAnalytics } from "../../../hooks/useAnalytics";
import PageHeader from "../../components/PageHeader";


export function NoMatchReportPage() {
    const { data, loading, error, refetch } = useAnalytics({metrics:['chat_sessions'], dimensions:['no_match_message']});


    return (
        <>
             <PageHeader title={"Reports"}>
                <></>
            </PageHeader>
            <Container>
                <Button onClick={refetch}></Button>
            {JSON.stringify(data)}
            </Container>
        </>
    )
}